@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Open Sans", sans-serif; */
  transition: all 0.1s ease-in-out;
  scroll-behavior: smooth;
}
:root {
  --navbar-height: 0;
}

/* universal classes */
.open-sans {
  font-family: "Open Sans", sans-serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}
.custom-capitalize {
  text-transform: capitalize !important;
}

/* navbar */

.is-scrolling {
  position: sticky;
  top: 0;
  transform: translateY(calc(var(--navbar-height) * -1));
  transition: all 0s ease-in;
}
.sticky-navbar {
  /* transform: translateY(0); */
  transform: translateY(0);
  transition: all 0.5s ease-in;
}
.sticky-navbar .inner-container {
  padding: 10px 0;
}
.sticky-navbar .logo-container {
  width: 180px;
}
.responsive-navlinks {
  transition: all 0.5s ease-in;
}
/* hero section */
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-dot {
  background-color: #25723e !important;
  width: 10px !important;
  height: 10px !important;
}
#myMenu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  display: flex;
  align-items: center;
  opacity: 0.98;
  z-index: 9;
}

#overlay {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease;
}
.stop-scrolling {
  overflow: hidden !important;
}
/* statement card css */
.statement-card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.statement-card.flipped {
  transform: rotateY(180deg);
}

.statement-card .front,
.statement-card .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.statement-card .back {
  transform: rotateY(180deg);
}

/* service card animations  */
